/**
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-metadata/#view-script
 */
const Dominio = "";

class PixelgrinoLoaderAnimation{
	constructor(){
		this.state=false;
		this.loaderItem = null;
	}	

	get_state(){
		return this.state;
	}

	get_Loader(){

		let loaderItem = document.createElement("div");
		let point1     = document.createElement("div");
		let text       = document.createElement("p");
		let texttext   = document.createTextNode("Tu galeria esta cargando");


		loaderItem.setAttribute("class","pixelgrino-loader-content");
		point1.setAttribute("class","pixelgrino-loader-point");
		text.setAttribute("class","pixelgrino-loader-mensaje");

		text.appendChild(texttext);
		loaderItem.appendChild(text);
		loaderItem.appendChild(point1);
		

		return loaderItem;
	}	

	start(){
		this.loaderItem = this.get_Loader();
		document.body.appendChild(this.loaderItem);
		this.state = true;
	}

	stop(){
		if (this.loaderItem) {
			this.loaderItem.remove();
		}
		this.loaderItem = null;
		this.state = false;
	}
		
}

//OBJECTO PARA PREPARA DESCARGA
class Descarga {

	constructor() {
		this.img_selection = []; //imageID
		this.permitidas    = 0; //Limite que se pueden seleccionar
		this.quedan        = 0; //Indica el numero de imagenes que se pueden seleccionar 
	}

	//Configura el limite de descargadas permitidas
	Set_permitidas(permitidas){ 
		this.permitidas = parseInt(permitidas); 
		this.quedan     = permitidas;
		this.imagenesDescarga = null;
	}

	//Añade imagen como selecciona
	Set_img_selection(id){ 
		this.img_selection.push(id);
		this.quedan--;
	}

	//Quita una imagen de la seleccion
	Remove_img_selection(id){
		this.img_selection = this.img_selection.filter( value => value != id );
		this.quedan++;
	}

	Get_Quedan(){
		return this.quedan;
	}

	Descargar(clave) {

		let url   = `${wpFunctions.root}pixelgrino/v1/entregas/descarga`;

		if(this.quedan == 0){

			if( confirm("Despues de esta descarga no podra realizar ninguna descarga, ¿Desea continuar?") ){

				fetch(url,{
					method: "POST",
					headers: {
					"Content-Type": "application/json",
					},
					body:JSON.stringify({
						'clave': clave,
						'ids': this.img_selection,
					})
				})
				.then( res => res.blob() )
				.then( blob => {
					// Ejecuta la descarga
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.href = url;
					a.download = `FOTOS-${wpFunctions.baseUrl}.zip`;
					document.body.appendChild(a);
					a.click();
					a.remove();
					window.URL.revokeObjectURL(url);
					// Recarga la página solo después de la descarga
					location.reload();
				})
				.catch( err => {
					return {
						error:err,
						mensaje:"No se ha podido obtener las imagenes"
					};
				});
			}
			
		}else if(this.quedan > 0){
			
			//Aun quedan imagenes que se podrias seleccionar y se emite el aviso al usuario
			if(confirm(` Aun puede seleccionar ${this.quedan} imagenes mas. ¿Esta seguro de querer ejecutar la descarga?`)){
				fetch(url,{
					method: "POST",
					headers: {
					"Content-Type": "application/json",
					},
					body:JSON.stringify({
						'clave': clave,
						'ids': this.img_selection,
					})
				})
				.then( res => res.blob() )
				.then( blob => {
					// Ejecuta la descarga
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.href = url;
					a.download = `FOTOS-${wpFunctions.baseUrl}.zip`;
					document.body.appendChild(a);
					a.click();
					a.remove();
					window.URL.revokeObjectURL(url);
					// Recarga la página solo después de la descarga
					location.reload();
				 	
				})
				.catch( err => {
					console.error("Error al recibir las imágenes en la descarga", err);
					alert("Hubo un error al intentar descargar las imágenes. Por favor, intenta de nuevo más tarde.");
				});
			}
	
		}else{
			alert(`Selecciona ${this.quedan} imágenes más`);
		}

	
	}
}

let descargaAPI = new Descarga();

/**
 * FUNCIONES OBSERVER - LAZYLOAD
 */
//Selecciona todas las imágenes que tienen la clase 'lazy-load'
const images = document.querySelectorAll(".lazy-load");

const cargarImagenLazyLoad = (image) => {
	image.src = image.getAttribute("data-src"); //Obtenemos la url del atributo auxiliar
	image.onload = () => image.removeAttribute("data-src"); //Se borra el atributo auxiliar
};

// Callback del observer
const callback = (entries, observer) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			const image = entry.target;
			cargarImagenLazyLoad(image); // Carga la imagen
			observer.unobserve(image); // Deja de observar la imagen ya cargada
		}
	});
};

//Configuración del IntersectionObserver
const observer = new IntersectionObserver(callback, {
	root: null, // Usa el viewport del navegador como contenedor
	rootMargin: "0px 0px 50px 0px", // Carga un poco antes de que la imagen entre en pantalla
	threshold: 0.1, // Carga cuando el 10% de la imagen es visible
});

let columnas = [
	//id de cada columna y total height
	[`pixelgrino-galery-col-1`, 0],
	[`pixelgrino-galery-col-2`, 0],
	[`pixelgrino-galery-col-3`, 0],
	[`pixelgrino-galery-col-4`, 0],
	[`pixelgrino-galery-col-5`, 0],
];

/**
 * FUNCION AUXILIAR
 * Se le pasa la altura de la nueva imagen que se va a colocar, esta funcion suma la altura total 
 * de todas la imagenes por columna y coloca la nueva imagen en la columna cuyo sea menor.
 * @return String , id de la columna en la que se debe colocar la imagen.
 */
function Select_column_image(altura_n_image) {

	let col_menor = 0;
	let result    = columnas[0][0];

	columnas.forEach( (columna,index) =>{
		
		if( columna[1] == 0 ){
			//Columna vacia
			columna[1] = columna[1] + altura_n_image;
			result = columna[0];
			return result;

		}else if( columna[1] <= columnas[col_menor][1]){
			//Se guarda el index de la columna menor
			col_menor = index; //
		}

		//Ultima interación del bucle actualiza la columna
		if(index == columnas.length-1){
			columnas[col_menor][1] = columnas[col_menor][1] + altura_n_image;
			result= columnas[col_menor][0];
		}
	});

	return result;
}

/**
 * OBTENER DATOS DE LA ENTREGA Y CREACION DE GALERIA
 * Peticion Api obtener datos de la entrega a trave de la clave
 */
function buscarEntrega() {
	//Loader para animacion de cargando
	let pixelgrinoloader = new PixelgrinoLoaderAnimation();
	pixelgrinoloader.start();

	//Datos busqueda
	let clave = document.getElementById("buscador_value").value;
	let url   = `${wpFunctions.root}pixelgrino/v1/entregas/identity`;

	//Configurar boton Descargar
	document.getElementById("btn_descargar_imagen").addEventListener("click",()=>{
		descargaAPI.Descargar(clave);
	});

	//Esta peticion busca una entrega mediante la clave
	fetch(`${url}?clave=${encodeURIComponent(clave)}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.then((data) => {
		
			//Se para el pixelgrino loader, animacion de cargando.
			pixelgrinoloader.stop();
			//console.log(data);

			if (data.message) {
				//No se encuentra la entrega
				document.getElementById("mensaje_usuario").innerText = data.message;
			} else {
				//Se encuentra la entrega
				let cliente = document.getElementById("data-cliente");
				let limite  = document.getElementById("data-limite");
				let total   = document.getElementById("data-total");

				cliente.innerText = data.cliente;

				//Configurar limite permitidas
				limite.innerText = data.permitidas;
				descargaAPI.Set_permitidas(data.permitidas);
				document.getElementById("quedan_menu_footer_value").innerText = data.permitidas;
				total.innerText = data.total;

				document.getElementById("mensaje_usuario").innerText = "";

				//Elimina el buscador
				document.getElementById("buscador_content").remove();

				//Imagen de background header
				document.getElementById("pixelgrino_image_efect",)
				.style.backgroundImage = ` url(${data.media.large.url[0]})`;

				document.getElementById( "pixelgrino_entrega_header").style.backgroundImage = ` url(${data.portada_url})`;

				//Mostrar galeria
				document.getElementById("Entrega_pixelgrino_content").style.display =
					"flex";
				document.getElementById(
					"pixelgrino_list_galery_content",
				).style.display = "grid";

				//Crea la imagen y la coloca donde corresponde
				data.media.large.url.forEach((element,index) => {

					//Create elems
					let image_content = document.createElement("li");
					let image_elem    = document.createElement("img");

					image_elem.setAttribute("data-src", element);
					image_elem.setAttribute("data-imageid", data.media.imageID[index]);
					image_elem.setAttribute("select", "false");
					image_elem.setAttribute(
						"class",
						"lazy-load pixelgrino-galery-col-img-1",
					);

					//Imagen Event para seleccion de imagenes de descarga
					image_elem.addEventListener("mousedown", (event) =>{

						//Boton izq raton
						if (event.button === 0) {

							let select      = event.target.getAttribute("select");
							let styleselect = event.target.getAttribute("class");

							if( select == "false" ){
								//Comprobar que no se supero el limite de descarga
								if(descargaAPI.Get_Quedan()>0){
									//Se añade la imagen como seleccionada
									event.target.setAttribute("select","true");
									event.target.setAttribute( "class", styleselect + " " + "select" );
									descargaAPI.Set_img_selection( event.target.getAttribute("data-imageid") );
								}else{
									alert(`El limite de fotos para descargar es ${data.permitidas}, quedan ${descargaAPI.Get_Quedan()}`);
								}
								
							}else{
								//Se quita la imagen de la seleccion
								event.target.setAttribute("select","false");
								styleselect = styleselect.replace("select","");
								event.target.setAttribute( "class", styleselect);
								descargaAPI.Remove_img_selection( event.target.getAttribute("data-imageid") );
							}
						}
						else if(event.button === 2){
							console.log("amplia imagen");
						}

						//Se actualiza el campo "Quedan"
						document.getElementById("quedan_menu_footer_value").innerText = descargaAPI.Get_Quedan();
					});

					//Observer Lazy load
					observer.observe(image_elem);
					image_content.appendChild(image_elem);

					//Se calcula donde se coloca la imagen
					let column_set = Select_column_image(data.media.large.altura[index]);
					
					//Coloca la imagen
					let columna = document.getElementById(column_set);
					columna.appendChild(image_content);
				});
			}
		})
		.catch((err) => {
			console.log(err);	
		});
}





//INIT
window.onload = () => {
	
	
	//Busqueda de entrega por clave
	document.getElementById("Buscar").addEventListener("click", () => {
		buscarEntrega();
	});

	//Desactivar context Menu
	document.addEventListener("contextmenu", (event) => {
		event.preventDefault(); // Previene el menú contextual en toda la página
	});

	
};
